import React, { useEffect, useState } from "react";
import axios from "../../../axios-backend";
import BackButton from "../../main/BackButton";
import ClickHere from "../../main/ClickHere";
import Line from "../../main/Line";
import SwiperSlider from "../../main/SwiperSlider";
import Text from "../../main/Text";
import classes from "../css/Authentication.module.css";

const ShowingCard = (props) => {
  const [images, setImages] = useState([]);
  const [email, setEmail] = useState("");
  const [authPortalText, setAuthPortalText] = useState("");
  const [modalToggle, setModalToggle] = useState(false);

  const getData = () => {
    if (sessionStorage["isAuth"]) {
      axios
        .get("/authenticationcard/", {
          params: {
            cardNumber: sessionStorage["cardNumber"],
            password: sessionStorage["password"],
          },
        })
        .then((data) => {
          if (data.data.status === 200) {
            axios
              .get("/get-imgs-by-pid", {
                params: {
                  id: data.data.id,
                  type: 2,
                },
              })
              .then((imgData) => {
                sessionStorage["transfer"] = data.data.id;
                setImages(imgData.data.imgs);
                setEmail(data.data.email);
                setAuthPortalText(data.data.authPortalText);
              });
          } else {
            alert("sorry. something is wrong.");
            props.history.goBack();
          }
        })
        .catch((error) => {
          alert("sorry. something is wrong.");
          props.history.goBack();
        });
    } else {
      props.history.push("/home");
    }
  };

  const handleTransfer = () => {
    props.history.push(`/authenticity/transfer`);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BackButton />

      <div className="singleContainer">
        <SwiperSlider>
          {images.map((i, index) => {
            return (
              <img
                key={index}
                src={
                  i.url.startsWith("s3")
                    ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${i.url}`
                    : `${process.env.REACT_APP_Url}${i.url}`
                }
                alt={index}
                className="singleImg"
              />
            );
          })}
        </SwiperSlider>
      </div>
      <Line type="top-line">the owners email address of this product is:</Line>
      <Line type="top-line">{email}</Line>
      <Line type="top-line">
        transfer ownership <ClickHere onClick={handleTransfer} />
      </Line>

      <Text className={classes.text}>
        {authPortalText.split("\n").map((item, index) => (
          <>
            {index !== 0 && <br />}
            {item}
          </>
        ))}
      </Text>
    </>
  );
};

export default ShowingCard;
