import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import axios from "../../axios-backend";
import classes from "../../components/home/css/Home.module.css";
import "../home/products/css/ProductDetails.css";
import BackButton from "../main/BackButton";
import Line from "../main/Line";
import SwiperSlider from "../main/SwiperSlider";
import Text from "../main/Text";

const Men = () => {
  const [types, setTypes] = useState([]);
  const [lastVisited, setLastVisited] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productId, setProductId] = useState({});
  const location = useLocation();

  // const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get("/getTypeByGender/", {
        params: { gender: 1 },
      });
      const data = response.data;
      if (data) {
        setTypes(data.types);
        const singlecollections = [];
        for (let i = 0; i < data?.types?.length; i++) {
          const collection = data?.types;
          if (collection[i].singlecollection) singlecollections.push(collection[i]);
        }
        await Promise.all(
          singlecollections.map(async (each) => {
            const response = await axios.get(`/has-single-product`, {
              params: {
                id: each.id,
              },
            });
            if (response.data.status === 200 && response.data.products.length) {
              const newProductId = { ...productId };
              newProductId[response?.data?.products[0]?.typeId] = response?.data?.products[0]?.id;
              setProductId(newProductId);
            }
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const scrollPosition = Cookies.get("scrollPositionMen");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      setLastVisited(parseInt(scrollPosition));
    }
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    Cookies.set("scrollPositionMen", currentScrollPosition);
  };

  return (
    <>
      <BackButton location={location}/>
      <Line type="bottom-line">
        <NavLink to="/world/membership" className={classes.navLink}>
          wonwonleywon membership
        </NavLink>
      </Line>
      {types.map((e, index) => {
        const images = JSON.parse(e.menproductimage);
        return (
          <div key={index}>
            {images?.length > 0 && (
              <>
                <div
                  style={{
                    minHeight: "300px",
                    maxHeight: "100%",
                    height: "fit-content",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="singleContainer"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SwiperSlider>
                      {images.map((i, index) => {
                        return (
                          <NavLink
                            to={{
                              pathname: `/products/${e.singlecollection ? "productdetails" : "productrelated"}`,
                              state: {
                                type: e.id,
                                gender: 1,
                                areaAmount: e.areaAmount,
                                productId: e.singlecollection ? productId[e.id] : undefined,
                                singleProduct: e.singlecollection,
                              },
                            }}
                          >
                            <img
                              key={index}
                              src={
                                i.startsWith("s3") ? 
                                  `https://wonwonleywon-images.s3.amazonaws.com/collection_images/${i}` : 
                                  `${process.env.REACT_APP_Url}${i}`
                              }
                              alt={index}
                              style={{
                                minHeight: "300px",
                                maxHeight: "500px",
                                height: "100%",
                                minWidth: "300px",
                                maxWidth: "500px",
                                width: "100%",
                                padding: "10px 0",
                                objectFit: "contain",
                              }}
                            />
                          </NavLink>
                        );
                      })}
                    </SwiperSlider>
                  </div>
                </div>
                <Line type="bottom-line" style={{ padding: "1.5rem 1rem", height: "fit-content", maxHeight: "100%", lineHeight: "1.45rem" }}>
                  <NavLink
                    to={{
                      pathname: `/products/${e.singlecollection ? "productdetails" : "productrelated"}`,
                      state: {
                        type: e.id,
                        gender: 1,
                        areaAmount: e.areaAmount,
                        productId: e.singlecollection ? productId[e.id] : undefined,
                        singleProduct: e.singlecollection,
                      },
                    }}
                    className={classes.navLink}
                  >
                    {e.label ? e.label : e.name}
                  </NavLink>
                </Line>
              </>
            )}
          </div>
        );
      })}
      <div style={{display: 'none'}}>
      {lastVisited && setTimeout(() => window.scrollTo(0, lastVisited), 0)}
      </div>
    </>
  );
};

export default Men;

