import React, { useEffect, useState } from 'react';
import Line from "../../main/Line";
import BackButton from '../../main/BackButton';
import { NavLink } from "react-router-dom";
import classes from "../css/Home.module.css";
import axios from '../../../axios-backend';
import SwiperSlider from '../../main/SwiperSlider';

export default function ProductDimensions(props) {
    const { type } = props.location.state;

    const [images, setImages] = useState([]);
    useEffect(() => {
        axios.get(`/get-imgs-by-pid`, {
            params: {
                id: type,
                type: 3
            }
        }).then(data => {
            setImages(data.data.imgs)
        });
    }, []);

    return <>
        <BackButton />
        <div className="singleContainer">

            <SwiperSlider>
                {images.map((i, index) => {
                    return (
                        <img
                            key={index}
                            src={`${process.env.REACT_APP_Url}${i.url}`}
                            alt={index}
                        />
                    );
                })}
            </SwiperSlider>
        </div>
    </>;
}
