import React from 'react';
import styles from './css/GuideWithDescription.module.css';


export default function GuideWithDescription(props) {
    const { name, largeImgUrl, fromCm, fromInches, description, type } = props.guide;
    const text = `Measure from the high point of your shoulder to ${props.unit === "IN" ? (fromInches + " inches") : (fromCm + " cm")}. This measurement should be approximately 
    where the t-shirt will end on your body if you 
    purchase this size.`
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {name}
            </div>

            <div className={styles.description}>
                {type !== 4 ?
                    description
                    :
                    text
                }
            </div>

            <img src={process.env.REACT_APP_Url + largeImgUrl} alt="size" className={styles.imgBox} />
        </div>
    )
}
