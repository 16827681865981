import React, { Component } from 'react';
import Line from '../../../main/Line';
import SelectionLine from './SelectionLine';
import ButtonsLine from './ButtonsLine';
import styles from './css/ThreeOptions.module.css';
import Slider from "react-slick";
import './css/SliderAll.css';

function Arrow(props) {
    const arrowClass = props.type === "prev" ? "fas fa-angle-left" : "fas fa-angle-right";
    return (
        <span className={props.type === "prev" ? props.styles.arrowPrev : props.styles.arrowNext} onClick={props.onClick}>
            <div></div>
            <i className={arrowClass} />
        </span>
    )
}


export default class ThreeOptions extends Component {
    state = {
        selected: 0,
        options: [],
        val: ''
    }
    changeAnswer = (val) => {
        const temp = JSON.parse(JSON.stringify(this.props.answer));
        temp[this.props.step.sIndex] = { shortName: this.props.step.shortName, val };
        this.props.setAnswer(temp);
    }
    componentDidMount() {
        console.log(this.props.step.options, this.props.step.options[0].text, this.props.step.type)
        this.setState({ options: this.props.step.options, val: this.props.step.options[0].text })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.step.stepId != this.props.step.stepId) {
            this.setState({ options: nextProps.step.options, val: nextProps.step.options[0].text })
        }
    }
    render() {
        return (
            <>
                <Line type="bottom-line" style={{ display: this.props.step.type === 6 ? "none" : "flex" }}>
                    Use the red support lines as guidance
                </Line>
                <Slider
                    ref={slider => (this.slider = slider)}
                    {...{
                        dots: false,
                        infinite: true,
                        fade: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        beforeChange: (current, next) => {
                            setTimeout(() => {
                                this.setState({ selected: next });
                            }, 10);
                        },
                    }}
                    prevArrow={<Arrow type="prev" styles={styles} />}
                    nextArrow={<Arrow type="next" styles={styles} />}
                    className="image-container-for-three-options"
                >
                    {this.state.options.map((i, index) => {
                        return (
                            <img
                                key={index}
                                src={process.env.REACT_APP_Url + i.imageUrl}
                                alt={index}
                                className="singleImg"
                            />
                        );
                    })}
                </Slider>
                {
                    this.state.options.map((item, index) => {
                        return <SelectionLine key={index} item={item}
                            selected={this.state.selected}
                            setSelected={() => {
                                this.changeAnswer(item.text);
                                this.slider.slickGoTo(index);
                                this.setState({ selected: index, val: item.text })
                            }} />
                    })
                }
                <ButtonsLine preStep={this.props.preStep}
                    nextStep={() => {
                        this.changeAnswer(this.state.val);
                        this.props.nextStep();
                    }}
                    skipStep={() => {
                        this.changeAnswer('skip');
                        this.props.nextStep();
                    }}
                />
            </>
        )
    }
}