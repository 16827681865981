import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../../axios-backend";
import BackButton from "../../main/BackButton";
import Line from "../../main/Line";
import SwiperSlider from "../../main/SwiperSlider";
import Text from "../../main/Text";
import classes from "../css/Home.module.css";
import "./css/ProductDetails.css";

const ProductDetails = (props) => {
  const { productId, type, areaAmount, gender, size, singleProduct = false } = props.location.state;

  const [images, setImages] = useState([]);
  const [typeDetails, setTypeDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [lastVisited, setLastVisited] = useState(null);


  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`/get-imgs-by-pid`, {
        params: {
          id: productId,
          type: 1,
          gender,
          usrType: "usr",
        },
      })
      .then((data) => {
        setImages(data.data.imgs);
      });
  }, []);

  useEffect(() => {
    if (type !== "") {
      setLoading(true);
      axios
        .get(`/type?id=${type}`)
        .then((data) => {
          if (data?.data?.type) setTypeDetails(data?.data?.type);
          setLoading(false);

          setIsLoading(false);
          setIsLoaded(true);
        })
        .catch((err) => {
          setLoading(false);

          setIsLoading(false);
          setIsLoaded(true);
        });
    }
  }, [type]);

  useEffect(() => {
    const scrollPosition = Cookies.get("scrollPositionPD");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      setLastVisited(parseInt(scrollPosition));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    Cookies.set("scrollPositionPD", currentScrollPosition);
  };

  // if it is preSale dont render anything else
  if (typeDetails?.preSale) {
    return (
      <>
        <BackButton />
        <Text>{typeDetails?.preSaleText}</Text>
      </>
    );
  }

  return !loading ? (
    <>
      <BackButton />
      {!typeDetails?.issoldout ? (
        <>
          <div className="singleContainer">
            <SwiperSlider>
              {images?.map((i, index) => {
                return (
                  <img
                    key={index}
                    src={
                      i.url.startsWith("s3")
                        ? `https://wonwonleywon-images.s3.amazonaws.com/product_images/${i.url}`
                        : `${process.env.REACT_APP_Url}${i.url}`
                    }
                    alt={index}
                  />
                );
              })}
            </SwiperSlider>
          </div>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: `/products/purchase`,
                state: { product: productId, type },
              }}
              className={classes.navLink}
            >
              Purchase
            </NavLink>
          </Line>

          {!singleProduct && (
            <Line type="top-line">
              <NavLink
                to={{
                  pathname: "/products/shopproduct",
                  state: { type, gender, areaAmount, size },
                }}
                className={classes.navLink}
              >
                Change Color
              </NavLink>
            </Line>
          )}
          {size && size !== "null" ? (
            <Line type="top-line">
              <NavLink
                to={{
                  pathname: "/products/sizepicker",
                  state: { type, gender, areaAmount },
                }}
                className={classes.navLink}
              >
                Change Size
              </NavLink>
            </Line>
          ) : null}

          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "philanthropy" },
              }}
              className={classes.navLink}
            >
              philanthropy
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "proPrice" },
              }}
              className={classes.navLink}
            >
              price
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "nameOf" },
              }}
              className={classes.navLink}
            >
              Name of Product
            </NavLink>
          </Line>
          {!singleProduct && (
            <Line type="top-line">
              <NavLink
                to={{
                  pathname: "/products/productvideo",
                  state: { type, gender },
                }}
                className={classes.navLink}
              >
                Collection Video
              </NavLink>
            </Line>
          )}

          {size && size !== "null" ? (
            <Line type="top-line">
              <NavLink
                to={{
                  pathname: "/products/productsizeguide",
                  state: { type, gender },
                }}
                className={classes.navLink}
              >
                Find your size
              </NavLink>
            </Line>
          ) : (
            <Line type="top-line">
              <NavLink
                to={{
                  pathname: "/products/material",
                  state: { tid: type, title: "dimensionDetails" },
                }}
                className={classes.navLink}
              >
                product dimensions
              </NavLink>
            </Line>
          )}

          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "material" },
              }}
              className={classes.navLink}
            >
              Material
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "madeIn" },
              }}
              className={classes.navLink}
            >
              Made In
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "proImage" },
              }}
              className={classes.navLink}
            >
              Product Images
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "care" },
              }}
              className={classes.navLink}
            >
              care
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "duties" },
              }}
              className={classes.navLink}
            >
              Duties
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "taxes" },
              }}
              className={classes.navLink}
            >
              taxes
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "shippingMethod" },
              }}
              className={classes.navLink}
            >
              Shipping Methods
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "shippingCost" },
              }}
              className={classes.navLink}
            >
              Shipping Costs
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "deliveryTime" },
              }}
              className={classes.navLink}
            >
              Delivery Time
            </NavLink>
          </Line>
          {/* <Line type="top-line">
        <NavLink to={{ pathname: "/products/material", state: { pid:productId, title: 'tracking' } }} className={classes.navLink}>
          Tracking
          </NavLink>
      </Line> */}
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "returns" },
              }}
              className={classes.navLink}
            >
              Returns
            </NavLink>
          </Line>
          {/* <Line type="top-line">
        <NavLink to={{ pathname: "/products/material", state: { pid:productId, title: 'refund' } }} className={classes.navLink}>
          Refund
          </NavLink>
      </Line> */}

          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "priceAndCurrency" },
              }}
              className={classes.navLink}
            >
              Prices and Currencies
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "changeCountry" },
              }}
              className={classes.navLink}
            >
              Change Country and Currency
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "paymentMethod" },
              }}
              className={classes.navLink}
            >
              Payment Methods
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink
              to={{
                pathname: "/products/material",
                state: { tid: type, title: "international" },
              }}
              className={classes.navLink}
            >
              International Transaction Fees
            </NavLink>
          </Line>
          <Line type="top-line">
            <NavLink to="/more/contact_us" className={classes.navLink}>
              Contact Us
            </NavLink>
          </Line>
        </>
      ) : (
        <Line style={{ fontWeight: "500" }}>this product is sold.</Line>
      )}
      <div style={{display: 'none'}}>
      {lastVisited && setTimeout(() => window.scrollTo(0, lastVisited), 0)}
      </div>
    </>
  ) : (
    <></>
  );
};
export default ProductDetails;
