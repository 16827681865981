import React from 'react';
import styles from './css/SizeLine.module.css';
import Text from '../../../main/Text'

export default function SizeLine(props) {
    console.log(props.guide)
    // 1 normal 2 less 3 greater 4 length
    const { name, fromCm, fromInches, toCm, toInches, imageUrl, type } = props.guide;
    const loadUnit = () => {
        if (type === 1) {
            if (props.unit === "IN") {
                return <>{fromInches} <span className={styles.sub}>IN</span>&nbsp;- {toInches} <span className={styles.sub}>IN</span></>
            } else {
                return <>{fromCm} <span className={styles.sub}>CM</span>&nbsp;- {toCm} <span className={styles.sub}>CM</span></>
            }

        } else if (type === 2) {
            if (props.unit === "IN") {
                return <>LESS THAN {toInches} <span className={styles.sub}>IN</span></>

            } else {
                return <>LESS THAN {toCm} <span className={styles.sub}>CM</span></>

            }
        } else if (type === 3) {
            if (props.unit === "IN") {
                return <>MORE THAN {fromInches} <span className={styles.sub}>IN</span></>
            } else {
                return <>MORE THAN {fromCm} <span className={styles.sub}>CM</span></>
            }
        } else {
            if (props.unit === "IN") {
                return <>{fromInches} <span className={styles.sub}>IN</span></>
            } else {
                return <>{fromCm} <span className={styles.sub}>CM</span></>
            }
        }
    }

    return (
        <>
            <div className={styles.container}>
                <img src={process.env.REACT_APP_Url + imageUrl} alt="size" className={styles.imgBox} />

                <div className={styles.contentBox}>
                    <div className={styles.wordsLine}>
                        {name}
                    </div>
                    <div className={styles.wordsLine}>
                        {loadUnit()}
                    </div>
                    <div>

                    </div>
                </div>


            </div>
            <div className={styles.additionalLine}>

                {
                    type === 4 ?
                        <Text className={styles.text}>
                            *the t-shirt will be approximately {props.unit === "IN" ? (" " + fromInches + " inches") : (" " + fromCm + " cm")} long if you order this size. if you want a different length, pick another size.
                        </Text>
                        : " "
                }
            </div>
        </>
    )
}
