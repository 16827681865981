import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BackButton from '../../main/BackButton';
import Text from '../../main/Text';
import Line from '../../main/Line';



class Episodes extends Component {
  render() {
    return (
      <>
        <BackButton />
        <div>
          <Text>
            we will provide content focused on the mind, body, knowledge, and entertainment to support the viewers to be the best version of themselves. this content will be in video, written, audio, visual, and or live event form. being one of one is hard. regardless of whether you have family or a significant other, you are one of one, and you are an individual. no people are identical. every person’s best version of themselves is different.  therefore, we do not have the answer. however, we hope pieces of our content will help guide people on the path to becoming the best version of themselves.
          </Text>
        </div>
        <Line type="top-line" >
          <Text style={{ paddingLeft: "0" }}> Click <NavLink style={{ color: 'black' }} to="/world/episodes/viewepisodes">here</NavLink> to view content</Text>
        </Line>
        <Line type="top-line" >
        </Line>
      </>
    );
  }
}
export default Episodes;
